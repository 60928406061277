:global .group-picker,
:global .local-picker {
  display: inline;
  padding: 1px 33px 1px 1px;
  color: #6600ff;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.65 2.35C12.2 0.9 10.21 0 8.00001 0C3.58001 0 0.0100098 3.58 0.0100098 8C0.0100098 12.42 3.58001 16 8.00001 16C11.73 16 14.84 13.45 15.73 10H13.65C12.83 12.33 10.61 14 8.00001 14C4.69001 14 2.00001 11.31 2.00001 8C2.00001 4.69 4.69001 2 8.00001 2C9.66001 2 11.14 2.69 12.22 3.78L9.00001 7H16V0L13.65 2.35Z' fill='%236600FF'/%3E%3C/svg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}

:global .text-input {
  /* color: #767678; */
  color: black;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #6600ff;
  text-decoration-skip-ink: auto;
  border: 0;
}

:global .text-input:focus,
:global .local-picker:focus,
:global .group-picker:focus {
  outline: none;
  background-color: rgba(102, 0, 255, 0.05);
}

:global .text-input::placeholder {
  color: #767678;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #6600ffc0;
  text-decoration-skip-ink: auto;
}

:global .sidenote {
  position: absolute;
  padding: 16px 16px 0px;
  width: 300px;
  background: #fafafc;
  border-radius: 2px;
  right: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #e8e8eb;
}

:global .sidenote p {
  font-size: 1.6rem;
  line-height: 1.5;
}

:global .check-row {
  display: block;
  position: relative;
  margin-bottom: 1.7rem;
}

:global .custom-check,
:global .check-row input[type="checkbox"] {
  position: absolute;
  top: 7px;
  left: -22px;
  width: 15px;
  height: 15px;
}

:global .check-row input[type="checkbox"] {
  display: block;
  opacity: 0;
}

:global .custom-check {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8333 2.16667V13.8333H2.16667V2.16667H13.8333ZM13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z' fill='%23767678'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

:global .check-row input[type="checkbox"]:focus {
  outline: 0;
  box-shadow: 0 0 3px 1px rgba(102, 0, 255, 0.5);
  opacity: 1;
}

:global .check-row input[type="checkbox"]:checked ~ .custom-check {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.7583 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.7583 0.5 13.8333 0.5ZM6.33333 12.1667L2.16667 8L3.34167 6.825L6.33333 9.80833L12.6583 3.48333L13.8333 4.66667L6.33333 12.1667Z' fill='%236600FF'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

@media (max-width: 1480px) {
  :global .sidenote {
    position: unset;
    margin-bottom: 15px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  :global .sidenote {
    position: unset;
    padding: 15px 20px 0px;
    width: 100%;
  }
}
